const defaultCalibration = () => {
  return {
    frequencies: {
      left: {
        125: {
          40: {
            decibels: 40,
            offset: 4,
          },
          45: {
            decibels: 45,
            offset: 4,
          },
          50: {
            decibels: 50,
            offset: 4,
          },
          55: {
            decibels: 55,
            offset: 4,
          },
          60: {
            decibels: 60,
            offset: 4,
          },
          65: {
            decibels: 65,
            offset: 4,
          },
          70: {
            decibels: 70,
            offset: 4,
          },
          75: {
            decibels: 75,
            offset: 4,
          },
          80: {
            decibels: 80,
            offset: 4,
          },
          85: {
            decibels: 85,
            offset: 4,
          },
          90: {
            decibels: 90,
            offset: 4,
          },
        },
        250: {
          40: {
            decibels: 40,
            offset: -6,
          },
          45: {
            decibels: 45,
            offset: -6,
          },
          50: {
            decibels: 50,
            offset: -6,
          },
          55: {
            decibels: 55,
            offset: -6,
          },
          60: {
            decibels: 60,
            offset: -6,
          },
          65: {
            decibels: 65,
            offset: -6,
          },
          70: {
            decibels: 70,
            offset: -6,
          },
          75: {
            decibels: 75,
            offset: -6,
          },
          80: {
            decibels: 80,
            offset: -6,
          },
          85: {
            decibels: 85,
            offset: -6,
          },
          90: {
            decibels: 90,
            offset: -6,
          },
        },
        500: {
          40: {
            decibels: 40,
            offset: -13,
          },
          45: {
            decibels: 45,
            offset: -13,
          },
          50: {
            decibels: 50,
            offset: -13,
          },
          55: {
            decibels: 55,
            offset: -13,
          },
          60: {
            decibels: 60,
            offset: -13,
          },
          65: {
            decibels: 65,
            offset: -13,
          },
          70: {
            decibels: 70,
            offset: -13,
          },
          75: {
            decibels: 75,
            offset: -13,
          },
          80: {
            decibels: 80,
            offset: -13,
          },
          85: {
            decibels: 85,
            offset: -13,
          },
          90: {
            decibels: 90,
            offset: -13,
          },
        },
        750: {
          40: {
            decibels: 40,
            offset: -19,
          },
          45: {
            decibels: 45,
            offset: -19,
          },
          50: {
            decibels: 50,
            offset: -19,
          },
          55: {
            decibels: 55,
            offset: -19,
          },
          60: {
            decibels: 60,
            offset: -19,
          },
          65: {
            decibels: 65,
            offset: -19,
          },
          70: {
            decibels: 70,
            offset: -19,
          },
          75: {
            decibels: 75,
            offset: -19,
          },
          80: {
            decibels: 80,
            offset: -19,
          },
          85: {
            decibels: 85,
            offset: -19,
          },
          90: {
            decibels: 90,
            offset: -19,
          },
        },
        1000: {
          40: {
            decibels: 40,
            offset: -21,
          },
          45: {
            decibels: 45,
            offset: -21,
          },
          50: {
            decibels: 50,
            offset: -21,
          },
          55: {
            decibels: 55,
            offset: -21,
          },
          60: {
            decibels: 60,
            offset: -21,
          },
          65: {
            decibels: 65,
            offset: -21,
          },
          70: {
            decibels: 70,
            offset: -21,
          },
          75: {
            decibels: 75,
            offset: -21,
          },
          80: {
            decibels: 80,
            offset: -21,
          },
          85: {
            decibels: 85,
            offset: -21,
          },
          90: {
            decibels: 90,
            offset: -21,
          },
        },
        1500: {
          40: {
            decibels: 40,
            offset: -22,
          },
          45: {
            decibels: 45,
            offset: -22,
          },
          50: {
            decibels: 50,
            offset: -22,
          },
          55: {
            decibels: 55,
            offset: -22,
          },
          60: {
            decibels: 60,
            offset: -22,
          },
          65: {
            decibels: 65,
            offset: -22,
          },
          70: {
            decibels: 70,
            offset: -22,
          },
          75: {
            decibels: 75,
            offset: -22,
          },
          80: {
            decibels: 80,
            offset: -22,
          },
          85: {
            decibels: 85,
            offset: -22,
          },
          90: {
            decibels: 90,
            offset: -22,
          },
        },
        2000: {
          40: {
            decibels: 40,
            offset: -22,
          },
          45: {
            decibels: 45,
            offset: -22,
          },
          50: {
            decibels: 50,
            offset: -22,
          },
          55: {
            decibels: 55,
            offset: -22,
          },
          60: {
            decibels: 60,
            offset: -22,
          },
          65: {
            decibels: 65,
            offset: -22,
          },
          70: {
            decibels: 70,
            offset: -22,
          },
          75: {
            decibels: 75,
            offset: -22,
          },
          80: {
            decibels: 80,
            offset: -22,
          },
          85: {
            decibels: 85,
            offset: -22,
          },
          90: {
            decibels: 90,
            offset: -22,
          },
        },
        3000: {
          40: {
            decibels: 40,
            offset: -20,
          },
          45: {
            decibels: 45,
            offset: -20,
          },
          50: {
            decibels: 50,
            offset: -20,
          },
          55: {
            decibels: 55,
            offset: -20,
          },
          60: {
            decibels: 60,
            offset: -20,
          },
          65: {
            decibels: 65,
            offset: -20,
          },
          70: {
            decibels: 70,
            offset: -20,
          },
          75: {
            decibels: 75,
            offset: -20,
          },
          80: {
            decibels: 80,
            offset: -20,
          },
          85: {
            decibels: 85,
            offset: -20,
          },
          90: {
            decibels: 90,
            offset: -20,
          },
        },
        4000: {
          40: {
            decibels: 40,
            offset: -12,
          },
          45: {
            decibels: 45,
            offset: -12,
          },
          50: {
            decibels: 50,
            offset: -12,
          },
          55: {
            decibels: 55,
            offset: -12,
          },
          60: {
            decibels: 60,
            offset: -12,
          },
          65: {
            decibels: 65,
            offset: -12,
          },
          70: {
            decibels: 70,
            offset: -12,
          },
          75: {
            decibels: 75,
            offset: -12,
          },
          80: {
            decibels: 80,
            offset: -12,
          },
          85: {
            decibels: 85,
            offset: -12,
          },
          90: {
            decibels: 90,
            offset: -12,
          },
        },
        6000: {
          40: {
            decibels: 40,
            offset: -16,
          },
          45: {
            decibels: 45,
            offset: -16,
          },
          50: {
            decibels: 50,
            offset: -16,
          },
          55: {
            decibels: 55,
            offset: -16,
          },
          60: {
            decibels: 60,
            offset: -16,
          },
          65: {
            decibels: 65,
            offset: -16,
          },
          70: {
            decibels: 70,
            offset: -16,
          },
          75: {
            decibels: 75,
            offset: -16,
          },
          80: {
            decibels: 80,
            offset: -16,
          },
          85: {
            decibels: 85,
            offset: -16,
          },
          90: {
            decibels: 90,
            offset: -16,
          },
        },
        8000: {
          40: {
            decibels: 40,
            offset: -18,
          },
          45: {
            decibels: 45,
            offset: -18,
          },
          50: {
            decibels: 50,
            offset: -18,
          },
          55: {
            decibels: 55,
            offset: -18,
          },
          60: {
            decibels: 60,
            offset: -18,
          },
          65: {
            decibels: 65,
            offset: -18,
          },
          70: {
            decibels: 70,
            offset: -18,
          },
          75: {
            decibels: 75,
            offset: -18,
          },
          80: {
            decibels: 80,
            offset: -18,
          },
          85: {
            decibels: 85,
            offset: -18,
          },
          90: {
            decibels: 90,
            offset: -18,
          },
        },
      },
      right: {
        125: {
          40: {
            decibels: 40,
            offset: 6,
          },
          45: {
            decibels: 45,
            offset: 6,
          },
          50: {
            decibels: 50,
            offset: 6,
          },
          55: {
            decibels: 55,
            offset: 6,
          },
          60: {
            decibels: 60,
            offset: 6,
          },
          65: {
            decibels: 65,
            offset: 6,
          },
          70: {
            decibels: 70,
            offset: 6,
          },
          75: {
            decibels: 75,
            offset: 6,
          },
          80: {
            decibels: 80,
            offset: 6,
          },
          85: {
            decibels: 85,
            offset: 6,
          },
          90: {
            decibels: 90,
            offset: 6,
          },
        },
        250: {
          40: {
            decibels: 40,
            offset: -4,
          },
          45: {
            decibels: 45,
            offset: -4,
          },
          50: {
            decibels: 50,
            offset: -4,
          },
          55: {
            decibels: 55,
            offset: -4,
          },
          60: {
            decibels: 60,
            offset: -4,
          },
          65: {
            decibels: 65,
            offset: -4,
          },
          70: {
            decibels: 70,
            offset: -4,
          },
          75: {
            decibels: 75,
            offset: -4,
          },
          80: {
            decibels: 80,
            offset: -4,
          },
          85: {
            decibels: 85,
            offset: -4,
          },
          90: {
            decibels: 90,
            offset: -4,
          },
        },
        500: {
          40: {
            decibels: 40,
            offset: -11,
          },
          45: {
            decibels: 45,
            offset: -11,
          },
          50: {
            decibels: 50,
            offset: -11,
          },
          55: {
            decibels: 55,
            offset: -11,
          },
          60: {
            decibels: 60,
            offset: -11,
          },
          65: {
            decibels: 65,
            offset: -11,
          },
          70: {
            decibels: 70,
            offset: -11,
          },
          75: {
            decibels: 75,
            offset: -11,
          },
          80: {
            decibels: 80,
            offset: -11,
          },
          85: {
            decibels: 85,
            offset: -11,
          },
          90: {
            decibels: 90,
            offset: -11,
          },
        },
        750: {
          40: {
            decibels: 40,
            offset: -17,
          },
          45: {
            decibels: 45,
            offset: -17,
          },
          50: {
            decibels: 50,
            offset: -17,
          },
          55: {
            decibels: 55,
            offset: -17,
          },
          60: {
            decibels: 60,
            offset: -17,
          },
          65: {
            decibels: 65,
            offset: -17,
          },
          70: {
            decibels: 70,
            offset: -17,
          },
          75: {
            decibels: 75,
            offset: -17,
          },
          80: {
            decibels: 80,
            offset: -17,
          },
          85: {
            decibels: 85,
            offset: -17,
          },
          90: {
            decibels: 90,
            offset: -17,
          },
        },
        1000: {
          40: {
            decibels: 40,
            offset: -19,
          },
          45: {
            decibels: 45,
            offset: -19,
          },
          50: {
            decibels: 50,
            offset: -19,
          },
          55: {
            decibels: 55,
            offset: -19,
          },
          60: {
            decibels: 60,
            offset: -19,
          },
          65: {
            decibels: 65,
            offset: -19,
          },
          70: {
            decibels: 70,
            offset: -19,
          },
          75: {
            decibels: 75,
            offset: -19,
          },
          80: {
            decibels: 80,
            offset: -19,
          },
          85: {
            decibels: 85,
            offset: -19,
          },
          90: {
            decibels: 90,
            offset: -19,
          },
        },
        1500: {
          40: {
            decibels: 40,
            offset: -22,
          },
          45: {
            decibels: 45,
            offset: -22,
          },
          50: {
            decibels: 50,
            offset: -22,
          },
          55: {
            decibels: 55,
            offset: -22,
          },
          60: {
            decibels: 60,
            offset: -22,
          },
          65: {
            decibels: 65,
            offset: -22,
          },
          70: {
            decibels: 70,
            offset: -22,
          },
          75: {
            decibels: 75,
            offset: -22,
          },
          80: {
            decibels: 80,
            offset: -22,
          },
          85: {
            decibels: 85,
            offset: -22,
          },
          90: {
            decibels: 90,
            offset: -22,
          },
        },
        2000: {
          40: {
            decibels: 40,
            offset: -23,
          },
          45: {
            decibels: 45,
            offset: -23,
          },
          50: {
            decibels: 50,
            offset: -23,
          },
          55: {
            decibels: 55,
            offset: -23,
          },
          60: {
            decibels: 60,
            offset: -23,
          },
          65: {
            decibels: 65,
            offset: -23,
          },
          70: {
            decibels: 70,
            offset: -23,
          },
          75: {
            decibels: 75,
            offset: -23,
          },
          80: {
            decibels: 80,
            offset: -23,
          },
          85: {
            decibels: 85,
            offset: -23,
          },
          90: {
            decibels: 90,
            offset: -23,
          },
        },
        3000: {
          40: {
            decibels: 40,
            offset: -22,
          },
          45: {
            decibels: 45,
            offset: -22,
          },
          50: {
            decibels: 50,
            offset: -22,
          },
          55: {
            decibels: 55,
            offset: -22,
          },
          60: {
            decibels: 60,
            offset: -22,
          },
          65: {
            decibels: 65,
            offset: -22,
          },
          70: {
            decibels: 70,
            offset: -22,
          },
          75: {
            decibels: 75,
            offset: -22,
          },
          80: {
            decibels: 80,
            offset: -22,
          },
          85: {
            decibels: 85,
            offset: -22,
          },
          90: {
            decibels: 90,
            offset: -22,
          },
        },
        4000: {
          40: {
            decibels: 40,
            offset: -12,
          },
          45: {
            decibels: 45,
            offset: -12,
          },
          50: {
            decibels: 50,
            offset: -12,
          },
          55: {
            decibels: 55,
            offset: -12,
          },
          60: {
            decibels: 60,
            offset: -12,
          },
          65: {
            decibels: 65,
            offset: -12,
          },
          70: {
            decibels: 70,
            offset: -12,
          },
          75: {
            decibels: 75,
            offset: -12,
          },
          80: {
            decibels: 80,
            offset: -12,
          },
          85: {
            decibels: 85,
            offset: -12,
          },
          90: {
            decibels: 90,
            offset: -12,
          },
        },
        6000: {
          40: {
            decibels: 40,
            offset: -14,
          },
          45: {
            decibels: 45,
            offset: -14,
          },
          50: {
            decibels: 50,
            offset: -14,
          },
          55: {
            decibels: 55,
            offset: -14,
          },
          60: {
            decibels: 60,
            offset: -14,
          },
          65: {
            decibels: 65,
            offset: -14,
          },
          70: {
            decibels: 70,
            offset: -14,
          },
          75: {
            decibels: 75,
            offset: -14,
          },
          80: {
            decibels: 80,
            offset: -14,
          },
          85: {
            decibels: 85,
            offset: -14,
          },
          90: {
            decibels: 90,
            offset: -14,
          },
        },
        8000: {
          40: {
            decibels: 40,
            offset: -19,
          },
          45: {
            decibels: 45,
            offset: -19,
          },
          50: {
            decibels: 50,
            offset: -19,
          },
          55: {
            decibels: 55,
            offset: -19,
          },
          60: {
            decibels: 60,
            offset: -19,
          },
          65: {
            decibels: 65,
            offset: -19,
          },
          70: {
            decibels: 70,
            offset: -19,
          },
          75: {
            decibels: 75,
            offset: -19,
          },
          80: {
            decibels: 80,
            offset: -19,
          },
          85: {
            decibels: 85,
            offset: -19,
          },
          90: {
            decibels: 90,
            offset: -19,
          },
        },
      },
    },
  };
};
export default defaultCalibration;
