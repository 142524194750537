import "./App.css";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { AppContainer } from "./modules/shared/AppContainer";
import { throttle } from "lodash";

import IframeContext from "./contexts/IframeContext";
import CalibrationContext from "./contexts/CalibrationContext";

import { checkIfIframe } from "./helpers/checkIfIframe";
import { LatusJourney } from "./userJourneys/latus/LatusJourney";

import { DemoJourney } from "./userJourneys/demo/DemoJourney";
import { NonIntegratedJourney } from "./userJourneys/non-integrated/NonIntegratedJourney";
import {
  PartyCodeContext,
  PartyCodeDispatchContext,
} from "./contexts/PartyCodeContext";
import { TestCodePage } from "./modules/home/ohc/TestCodePage";
import { AdminApi } from "./modules/hearing-test/services/admin-api";
import {
  EnabledTestsContext,
  EnabledTestsDispatchContext,
} from "./contexts/EnabledTestsContext";
import { ClinicDetails, Tests } from "./modules/shared/models/ClinicDetails";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";
import { InactivityModal } from "./modules/inactivity/InactivityModal";
import { useAtomValue, useSetAtom } from "jotai";
import { resetAllAtoms, userDetailsAtom } from "./atoms/atoms";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const queryClient = new QueryClient();

export const App = () => {
  const isIframe = checkIfIframe(window.location.pathname);
  const navigate = useNavigate();

  const [clinicDetails, dispatchClinicDetails] = useReducer(clinicReducer, {
    slug: "",
    name: "",
  });
  const [enabledTests, dispatchEnabledTests] = useReducer(
    enabledTestsReducer,
    {}
  );
  const [showInactivityModal, setShowInactivityModal] = useState(false);
  const userDetails = useAtomValue(userDetailsAtom);

  useEffect(() => {
    if (sessionStorage.getItem("partyCode")) {
      if (sessionStorage.getItem("partyCode") === "demo") {
        dispatchClinicDetails({
          type: "setClinic",
          payload: { slug: "demo", name: "Demo" },
        });
      } else {
        AdminApi.getCompanyByTestId(sessionStorage.getItem("partyCode")!)
          .then((company) => {
            if (company.slug) {
              const { slug, name, ...tests } = company;
              dispatchClinicDetails({
                type: "setClinic",
                payload: { slug, name },
              });
              dispatchEnabledTests({
                type: "setTests",
                payload: tests,
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, []);

  const [calibration, setCalibration] = useState({});

  const selectUserJourneyRoutes = (
    clinicDetails: ClinicDetails,
    isIframe: boolean
  ) => {
    if (
      window.location.href.includes("/occupational-health/latus") ||
      window.location.href.includes("/result/latus")
    ) {
      return <LatusJourney />;
    }

    switch (clinicDetails.slug) {
      case "":
        return <TestCodePage />;
      case "demo":
        return <DemoJourney />;
      default:
        return <NonIntegratedJourney />;
    }
  };

  const userJourneyRoutes = selectUserJourneyRoutes(clinicDetails, isIframe);

  //inactivity stuff
  const inactivityTimeRef = useRef<NodeJS.Timeout>();
  //after inactivity time ref reaches 30 minutes, redirect time ref will start
  const redirectTimeRef = useRef<NodeJS.Timeout>();

  const resetAtoms = useSetAtom(resetAllAtoms);

  const handleAfterInactivityModalShown = useCallback(() => {
    console.log("clearing session storage");
    let navigateToDeepLink = false;
    if (clinicDetails.slug === "latus") {
      navigateToDeepLink = true;
    }
    sessionStorage.clear();
    resetAtoms(true);
    dispatchClinicDetails({
      type: "setClinic",
      payload: { slug: "", name: "" },
    });
    dispatchEnabledTests({ type: "setTests", payload: {} });
    if (navigateToDeepLink) {
      const testId = userDetails.externalId;

      window.location.href =
        "https://yodhadatacollection.app.link/EUohp9obuDb?testid=" + testId;
    } else {
      navigate("/occupational-health");
    }
    setShowInactivityModal(false);
    clearTimeout(inactivityTimeRef.current);
    clearTimeout(redirectTimeRef.current);
  }, [resetAtoms, clinicDetails.slug, navigate, userDetails.externalId]);

  const startRedirectionTimer = useCallback(() => {
    redirectTimeRef.current = setTimeout(
      handleAfterInactivityModalShown,
      30 * 1000
    ); // 30 seconds
  }, [handleAfterInactivityModalShown]);

  const triggerFunction = useCallback(() => {
    console.log("30 minutes of inactivity detected!");
    setShowInactivityModal(true);
    startRedirectionTimer();
  }, [startRedirectionTimer, setShowInactivityModal]);

  const startInactivityTimer = useCallback(() => {
    inactivityTimeRef.current = setTimeout(triggerFunction, 30 * 60 * 1000); // 30 minutes
  }, [triggerFunction]);

  const resetTimeout = useCallback(() => {
    clearTimeout(inactivityTimeRef.current);
    clearTimeout(redirectTimeRef.current);

    setShowInactivityModal(false);
    startInactivityTimer();
  }, [startInactivityTimer]);

  useEffect(() => {
    // Set up the event listeners
    const events = [
      "mousemove",
      "keydown",
      "click",
      "touchstart",
      "touchmove",
      "touchend",
    ];

    const throttledResetTimeout = throttle(resetTimeout, 10000); // Throttle once outside

    for (let event of events) {
      window.addEventListener(event, throttledResetTimeout); // Use the throttled function directly
    }

    // Initialize the inactivity timeout
    startInactivityTimer();

    return () => {
      // Cleanup the event listeners when the component is unmounted
      for (let event of events) {
        window.removeEventListener(event, throttledResetTimeout);
      }
    };
  }, [resetTimeout, startInactivityTimer]);
  //end inactivity stuff

  return (
    <QueryClientProvider client={queryClient}>
      <IframeContext.Provider value={isIframe}>
        <CalibrationContext.Provider value={[calibration, setCalibration]}>
          <PartyCodeContext.Provider value={clinicDetails}>
            <PartyCodeDispatchContext.Provider value={dispatchClinicDetails}>
              <EnabledTestsContext.Provider value={enabledTests}>
                <EnabledTestsDispatchContext.Provider
                  value={dispatchEnabledTests}
                >
                  <React.Fragment>
                    {isIframe ? (
                      userJourneyRoutes
                    ) : (
                      <>
                        {showInactivityModal && <InactivityModal />}
                        <AppContainer>{userJourneyRoutes}</AppContainer>
                      </>
                    )}
                  </React.Fragment>
                </EnabledTestsDispatchContext.Provider>
              </EnabledTestsContext.Provider>
            </PartyCodeDispatchContext.Provider>
          </PartyCodeContext.Provider>
        </CalibrationContext.Provider>
      </IframeContext.Provider>
    </QueryClientProvider>
  );
};

function clinicReducer(
  _: any,
  action: { type: string; payload: ClinicDetails }
) {
  switch (action.type) {
    case "setClinic":
      return action.payload;
    default:
      return { slug: "demo", name: "Demo" };
  }
}

function enabledTestsReducer(_: any, action: { type: string; payload: Tests }) {
  switch (action.type) {
    case "setTests":
      return action.payload;
    default:
      return {};
  }
}

export default App;
