import React, { useRef, useEffect, useState } from "react";
import { init, getInstanceByDom } from "echarts";
import type { CSSProperties } from "react";
import type { EChartsOption, ECharts, SetOptionOpts } from "echarts";
import { HseV1Categorisation } from "../../shared/models/client-categorisation";

export interface AudiogramChartProps {
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  theme?: "light" | "dark";
  result: HseV1Categorisation;
}

export function AudiogramChart({
  style,
  settings,
  loading,
  theme,
  result,
}: AudiogramChartProps): JSX.Element {
  const chartRef = useRef<HTMLDivElement>(null);
  const [option, setOption] = useState<EChartsOption>({
    labels: ["250", "500", "1000", "2000", "3000", "4000", "6000", "8000"],
    datasets: [
      {
        label: "Left Ear",
        data: [1, 2, 3, 4, 5, 6, 7, 30],
        borderColor: "rgb(53, 162, 235)",
      },
      {
        label: "Right Ear",
        data: [8, 7, 6, 5, 4, 3, 2, -5],
        borderColor: "rgb(255, 120, 120)",
      },
    ],
  });

  useEffect(() => {
    // Initialize chart
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current, theme, { renderer: "canvas" });
    }

    const labels = ["125", "250", "500", "1k", "2k", "3k", "4k", "6k", "8k"];

    const options: EChartsOption = {
      backgroundColor: "#FFFFFF",
      color: ["#0000FF", "#FF0000"], // Blue for Left Ear, Red for Right Ear
      title: {
        text: "Audiogram Graph",
        top: "90%",
        left: "25%",
        textAlign: "center",
        textStyle: {
          fontSize: 20,
          align: "center",
        },
      },
      legend: {
        right: "10%",
        top: "90%",
        orient: "vertical",
        data: ["Left Ear", "Right Ear"],
        backgroundColor: "#fff",
        textStyle: {
          fontSize: 16,
          color: "#000",
        },
      },
      xAxis: {
        position: "top",
        name: "Frequency in Hertz (Hz)",
        nameLocation: "middle",
        nameGap: 30, // position the title
        axisLabel: {
          rotate: 0, // Ensures labels on the x-axis are horizontal
          fontSize: 18,
        },
        boundaryGap: false,
        axisTick: {
          alignWithLabel: true,
        },
        nameTextStyle: {
          fontSize: 20,
        },
        splitLine: {
          show: true,
          lineStyle: {
            type: "solid",
          },
        },
        type: "category",
        data: labels,
        axisLine: {
          symbol: "arrow",
          lineStyle: {
            type: "dashed",
          },
        },
      },
      yAxis: {
        name: "Hearing Level in decibels (dB)",
        nameLocation: "middle",
        nameGap: 40, // position the title
        nameRotate: 90, // Rotates the y-axis title to be vertical
        axisLabel: {
          rotate: 0, // Ensures labels on the y-axis are horizontal
          fontSize: 18,
        },
        nameTextStyle: {
          fontSize: 20,
        },
        type: "value",
        axisTick: {
          length: 5,
          lineStyle: {
            type: "dashed",
          },
        },
        inverse: true,
        min: -10,
        max: 120,
        interval: 10,
      },
      series: [
        {
          name: "Right Ear",
          // We don't have values for 125 and 250 hz so we add dashes to the start of the array
          data: ["-", "-", ...result.rightCategorisation.audiogramIntensities],
          type: "line",
          lineStyle: {
            opacity: 0.5,
          },
          symbol: "emptyCircle",
          symbolSize: 15,
          color: "red",
        },
        {
          name: "Left Ear",
          // We don't have values for 125 and 250 hz so we add dashes to the start of the array
          data: ["-", "-", ...result.leftCategorisation.audiogramIntensities],
          type: "line",
          lineStyle: {
            opacity: 0.5,
          },
          symbol:
            "path://m285.08 230.397L456.218 59.27c6.076-6.077 6.076-15.911 0-21.986L423.511 4.565c-2.913-2.911-6.866-4.55-10.992-4.55-4.127 0-8.08 1.639-10.993 4.55l-171.138 171.14L59.25 4.565c-2.913-2.911-6.866-4.55-10.993-4.55-4.126 0-8.08 1.639-10.992 4.55L4.558 37.284c-6.077 6.075-6.077 15.909 0 21.986l171.138 171.128L4.575 401.505c-6.074 6.077-6.074 15.911 0 21.986l32.709 32.719c2.911 2.911 6.865 4.55 10.992 4.55s8.08-1.639 10.994-4.55l171.117-171.12 171.118 171.12c2.913 2.911 6.866 4.55 10.993 4.55 4.128 0 8.081-1.639 10.992-4.55l32.709-32.719c6.074-6.075 6.074-15.909 0-21.986L285.08 230.397z",
          symbolSize: 15,
          color: "blue",
        },
      ],
    };

    setOption(options);

    // Add chart resize listener
    // ResizeObserver is leading to a bit janky UX
    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener("resize", resizeChart);

    // Return cleanup function
    return () => {
      chart?.dispose();
      window.removeEventListener("resize", resizeChart);
    };
  }, [
    result.leftCategorisation.audiogramIntensities,
    result.rightCategorisation.audiogramIntensities,
    theme,
  ]);

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      chart?.setOption(option, settings);
    }
  }, [option, settings, theme]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

  useEffect(() => {
    // Update chart
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      loading === true ? chart?.showLoading() : chart?.hideLoading();
    }
  }, [loading, theme]);

  return (
    <div ref={chartRef} style={{ width: "100%", height: "100px", ...style }} />
  );
}
